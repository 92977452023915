import React, { useState, HTMLInputTypeAttribute } from 'react'
import { Button, Spacing, useSpacing } from '..'
import { getCookie } from '../../utils'
import { useForm, Controller } from 'react-hook-form'
import clsx from 'clsx'
import {
  Checkbox,
  Dropdown,
  handlePhoneValidation,
  Input,
  Label,
  Message,
  Telephone,
  Textarea,
} from './Components'

export interface FormComponentProps {
  formid: string
  anchorTag: string
  title?: string
  subtitle?: string
  field: {
    label: string
    name: string
    isConsent: boolean
    inputType:
      | 'email'
      | 'tel'
      | 'text'
      | 'textarea'
      | 'number'
      | 'url'
      | 'date'
      | 'checkbox'
      | HTMLInputTypeAttribute
    placeholder?: string
    span: number
    helpertext?: string // add to Dato
    dropdown?: { option: string }[]
  }[]
  buttonLabel: string
  successMessage: string
  spacing: Spacing[]
}

// Hubpot submission forms:
// https://legacydocs.hubspot.com/docs/methods/forms/submit_form#:~:text=Form%20submissions%20from%20external%20sources,a%20Non%2DHubSpot%20form%20label.

/** Renders a two column grid of input fields, and corresponds to the
 * DatoCMS form component. To span columns, simply provide the span
 * field to the field in DatoCMS.
 */
export const FormComponent = ({ data }: { data: FormComponentProps }) => {
  const [status, setStatus] = useState('')
  let hasConsent = { name: '', label: '' }
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const formId = data.formid

  const onSubmit = async (data: any) => {
    setStatus('pending')

    const ipRes = await fetch('https://jsonip.com/')
    const { ip: ipAddress } = await ipRes.json()
    const hutk = getCookie('hubspotutk')
    const loc = location?.href

    const res = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/48233040/${formId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: Object.keys(data).map(key => ({
            name: key,
            value: data[key],
          })),
          context: {
            hutk,
            pageUri: loc,
            // Return page name as the last index of the url
            pageName: loc.substring(loc.lastIndexOf('/')),
            ipAddress,
          },
          // Optionally creates consent for GDPR forms.
          // Requires additional work to match if communications
          // are requested. Currently only handles one consent
          // per form.
          ...(hasConsent.name && {
            legalConsentOptions: {
              consent: {
                consentToProcess: true,
                text: hasConsent.label,
              },
            },
          }),
        }),
      },
    )
    if (res.ok && res.status < 400) {
      setStatus('completed')
      reset()
    } else setStatus('failed')
  }

  const pending = status === 'pending'
  const completed = status === 'completed'
  const failed = status === 'failed'

  return (
    <div
      className={`px-4 max-w-page md:mx-auto md:w-[768px] md:px-24 ${spacing}`}
    >
      <div
        id={data.anchorTag}
        className={`px-4 py-4 rounded-3xl border border-gray-600 md:px-24 md:py-[80px]`}
        role="form"
        aria-label="submit a form"
      >
        {completed ? (
          <Message message={data.successMessage} success />
        ) : (
          <>
            {data.title && (
              <div className="text-xl font-bold text-blue-800">
                {data.title}
              </div>
            )}
            {data.subtitle && (
              <div className="text-base text-gray-600">{data.subtitle}</div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} id={formId}>
              <div
                className={clsx(
                  `grid gap-4 items-end grid-col-1 md:grid-cols-2`,
                )}
              >
                {data.field.map(item => {
                  if (item.isConsent) hasConsent = item
                  return (
                    <div
                      className={`md:col-span-${item.span} w-full md:pt-6`}
                      key={item.name}
                    >
                      {/* Checkboxes come before the label */}
                      {item.inputType === 'checkbox' && (
                        <Checkbox
                          register={register}
                          required
                          name={item.name}
                          errors={errors}
                        />
                      )}
                      {/* Set the form label */}
                      <Label
                        label={item.label}
                        helperText={item.helpertext}
                        name={item.name}
                        errors={errors}
                      />
                      {/* Set the form input sections dynamically */}
                      <div>
                        {item.inputType === 'textarea' ? (
                          <Textarea
                            register={register}
                            name={item.name}
                            required
                            placeholder={item.placeholder}
                            errors={errors}
                          />
                        ) : item.inputType === 'tel' ? (
                          <Controller
                            name={item.name}
                            control={control}
                            rules={{
                              validate: value => handlePhoneValidation(value),
                            }}
                            render={({ field: { onChange, value } }) => (
                              <Telephone
                                value={value}
                                onChange={onChange}
                                errors={errors}
                                name={item.name}
                              />
                            )}
                          />
                        ) : item.inputType === 'dropdown' ? (
                          <Dropdown
                            register={register}
                            name={item.name}
                            label={item.label}
                            required
                            placeholder={item.placeholder}
                            errors={errors}
                            options={
                              item.dropdown?.map(
                                option => option.option,
                              ) as string[]
                            }
                          />
                        ) : (
                          item.inputType !== 'checkbox' && (
                            <Input
                              name={item.name}
                              register={register}
                              errors={errors}
                              placeholder={item.placeholder}
                              required
                              type={item.inputType}
                              label={item.label}
                            />
                          )
                        )}
                      </div>
                    </div>
                  )
                })}
                {failed && <Message failure />}
                <Button
                  type="submit"
                  disabled={pending}
                  size="large"
                  className="px-12 py-4 mt-8 text-xl font-medium rounded-full md:col-span-2"
                >
                  {data.buttonLabel}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  )
}
